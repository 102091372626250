
import {
  onMounted,
  onUnmounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBodyPager } from "@/apis/interface/Resp";
import { IAdmin, AdminFilter, AdminModel } from "@/apis/interface/IAdmin";
import bus from "@/utils/bus";
import DialogRole from "./DialogRole.vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  components: {
    DialogRole: DialogRole
  },
  setup() {
    const apiAdmin = inject("apiAdmin") as IAdmin;
    const dialogRole = ref(null);

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50
    });

    const filter: AdminFilter = reactive({
      username: "",
      realname: "",
      phone: "",
      gender: null
    });

    let clearFilter = () => {
      filter.username = "";
      filter.realname = "";
      filter.phone = "";
      filter.gender = null;
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<AdminModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      dataList.value = [];
      const resp = await apiAdmin.getList(pageParams, filter);
      const pager: RespBodyPager<AdminModel> = resp;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      pageParams.total = pager.object.totalCount;
      dataList.value = pager.object.listData;
      // console.log("==>", dataList);
      loading.value = false;
    };

    let clickCreate = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new AdminModel({})
      });
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    let deleteRow = async (id: number) => {
      let result = await apiAdmin.remove([id]);
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！"
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗"
        });
    };

    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );
    watch(
      () => pageParams.aaSize,
      () => {
        pageParams.aaIndex = 1;
        loadData();
      }
    );

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let tableHeight = ref(0);

    let onClickShowRole = (admin: AdminModel) => {
      (dialogRole.value as any).setData(admin);
    };

    return {
      loading,
      dialogRole,
      tableHeight,
      pageParams,
      filter,
      dataList,
      statusFormat,
      clickCreate,
      loadData,
      onClickShowRole,
      deleteRow,
      clearFilter
    };
  }
});
