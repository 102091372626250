
import { defineComponent, inject, ref } from "vue";
import { AdminModel } from "@/apis/interface/IAdmin";
import { IAdminRole, AdminRoleModel } from "@/apis/interface/IAdminRole";
import { ISysRole, SysRoleModel } from "@/apis/interface/ISysRole";
import { RespBodyList } from "@/apis/interface/Resp";
import { ElMessage } from "element-plus";

export default defineComponent({
  setup() {
    const apiAdminRole = inject("apiAdminRole") as IAdminRole;
    const apiSysRole = inject("apiSysRole") as ISysRole;
    let dialogVisible = ref(false);

    let adminData: AdminModel;
    let sysRoles = ref<Array<SysRoleModel>>([]);
    let checkedRoleList = ref<Array<number | undefined>>([]);

    let setData = async (admin: AdminModel) => {
      if (admin.id) {
        adminData = admin;
        dialogVisible.value = true;

        let ret1 = (await apiSysRole.getList(
          "management"
        )) as RespBodyList<SysRoleModel>;
        sysRoles.value = ret1.object;

        let ret2 = (await apiAdminRole.getList(
          admin.id,
          undefined
        )) as RespBodyList<AdminRoleModel>;

        checkedRoleList.value = ret2.object.map((item) => item.roleId);
      }
    };

    let saveAdminRole = async (roleId: number, value: boolean) => {
      if (adminData.id) {
        let result = await apiAdminRole.update(adminData.id, roleId, value);
        if (result.object) {
          ElMessage({
            type: "success",
            message: "修改角色成功"
          });
        } else {
          ElMessage({
            type: "error",
            message: "修改角色失败"
          });
        }
      }
    };

    return {
      dialogVisible,
      sysRoles,
      checkedRoleList,
      setData,
      saveAdminRole
    };
  }
});
